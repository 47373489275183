<template>
  <el-dialog width="450px" title="新增岗位" append-to-body v-model="innerVisible" custom-class="custom-dialog">
    <div class="form-wrap">
      <el-form size="small" ref="updateForm" :rules="rules" :model="form">
        <el-form-item label="岗位名称" prop="positionName">
          <el-input maxlength="20" v-model="form.positionName"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="edit-footer">
        <el-button type="primary" size="small" :loading="form.loading" @click="saveClick">保存</el-button>
        <el-button size="small" @click="innerVisible = false">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { reactive, onMounted, computed, watch, ref } from 'vue'
import { addPosition } from '@/apis'
export default {
  name: 'positionAdd',
  props: {
    visible: { type: Boolean, required: true }
  },
  emits: ['update:visible', 'success'],
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const updateForm = ref(null)
    const innerVisible = computed({
      get() {
        return props.visible
      },
      set(val) {
        emit('update:visible', val)
      }
    })
    const form = reactive({
      loading: false,
      positionName: ''
    })
    const rules = {
      positionName: { max: 20, message: '最大限制为20个字符', trigger: 'change' }
    }
    const saveClick = async () => {
      if (!form.positionName) return false
      updateForm.value.validate(async valid => {
        if (!valid) return false
        form.loading = true
        const result = await addPosition({
          name: form.positionName
        })
        form.loading = false
        if (result) {
          emit('update:visible', false)
          emit('success', form.positionName)
          form.positionName = ''
        }
      })
    }
    onMounted(() => {})
    return {
      updateForm,
      innerVisible,
      form,
      saveClick,
      rules
    }
  }
}
</script>
<style lang="scss" scoped>
.form-wrap {
  padding: 10px 40px 0;
}
.edit-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    width: 94px;
    margin: 0;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}
</style>

<template>
  <div class="search-user-page">
    <el-table
      cell-class-name="custom-table-cell"
      header-row-class-name="custom-table-header"
      :data="list"
      empty-text="暂无数据"
      style="width: 100%"
    >
      <el-table-column align="center" type="selection" width="60"></el-table-column>
      <el-table-column align="left" header-align="left" show-overflow-tooltip prop="name" label="姓名" width="120">
        <template #default="scope">
          <wxview type="userName" :openid="scope.row.name">{{ scope.row.name }}</wxview>
        </template>
      </el-table-column>
      <el-table-column align="left" header-align="left" prop="departmentName" label="部门">
        <template #default="scope">
          <el-popover placement="right-end" popper-class="dark-popover" :width="220" trigger="hover">
            <div>
              <template v-for="(item, index) in scope.row.departmentName" :key="item">
                <span type="departmentName" :openid="item">{{ item }}</span>
                <template v-if="index !== scope.row.departmentName.length - 1">，</template>
              </template>
            </div>
            <template #reference>
              <div class="ellipsis column-popover">
                <template v-for="(item, index) in scope.row.departmentName" :key="item">
                  <span type="departmentName" :openid="item">{{ item }}</span>
                  <template v-if="index !== scope.row.departmentName.length - 1">，</template>
                </template>
              </div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column align="left" header-align="left" prop="positionName" label="职位" width="150"></el-table-column>
      <el-table-column align="left" header-align="left" prop="mobile" label="手机" width="140"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: 'searchUser',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>

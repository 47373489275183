<template>
  <div class="menu-action">
    <div class="menu-action__trigger" :style="{ color }" @click="toggleShowAction">
      <i class="iconfont icon-menu"></i>
    </div>
    <div class="menu-action__list" v-show="showAction">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, h } from 'vue'
export const MenuActionItem = (props, context) => {
  return h(`div`, { ...context.attrs, class: `menu-action__list__item` }, context.slots)
}
export default defineComponent({
  props: {
    color: { type: String, default: '#fff' }
  },
  setup() {
    const state = reactive({
      showAction: false
    })

    const toggleShowAction = () => {
      state.showAction = !state.showAction
    }
    document.addEventListener('click', function (e) {
      if (!['iconfont icongengduo', 'menu-action__trigger', 'iconfont icon-menu'].includes(e.target.className)) {
        state.showAction = false
      }
    })
    return { ...toRefs(state), toggleShowAction }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>

<template>
  <div class="role-tree-wrap">
    <el-tree
      v-if="state.show"
      ref="treeRef"
      :data="state.defaultTreeData"
      :highlight-current="true"
      node-key="id"
      :default-expanded-keys="state.treeDefaultExpand"
      :current-node-key="state.current.id"
      :default-expand-all="false"
      :expand-on-click-node="false"
      :props="{ children: 'childList', label: 'name' }"
      @current-change="onCurrentChange"
    >
      <template #default="{ node, data }">
        <div class="custom-tree-node ellipsis">
          <span class="iconfont" :class="[node.isCurrent ? 'icon-folder-open' : 'icon-folder']"></span>
          <span>{{ node.label }}</span>
        </div>
        <div v-if="node.level === 1 && data.id === 'position'" @click.stop="addPosition" class="position-add">
          <i :class="[node.isCurrent ? 'el-icon-plus position-add-selected' : 'el-icon-plus']"></i>
        </div>
        <menu-action v-if="node.isCurrent && node.level > 1 && data.pid === 'position'">
          <menu-action-item @click.stop="onActionClick('edit', data, node)">编辑</menu-action-item>
          <menu-action-item @click.stop="onActionClick('delete', data, node)">删除</menu-action-item>
          <menu-action-item :disabled="!canMoveUp(node)" @click.stop="onActionClick('up', data, node)">
            上移
          </menu-action-item>
          <menu-action-item :disabled="!canMoveDown(node)" @click.stop="onActionClick('down', data, node)">
            下移
          </menu-action-item>
        </menu-action>
      </template>
    </el-tree>
    <position-add v-model:visible="state.showAdd" @success="onAddPositionSuccess"></position-add>
    <position-edit
      v-model:visible="state.showEdit"
      @success="onEditPositionSuccess"
      :id="state.current?.id || ''"
      :name="state.current?.name || ''"
      :create-time="state.current?.createTime || ''"
      :sort="state.current?.sort || 0"
    ></position-edit>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { nextTick, reactive, onMounted, watch, ref } from 'vue'
import MenuAction, { MenuActionItem } from '@/views/components/menu-action'
import { useDeleteConfirm } from '@/hooks/ui'
import { getPositionList, deletePosition, updatePositionSort } from '@/apis'
import PositionAdd from './position-add.vue'
import PositionEdit from './position-edit.vue'
import { ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'
export default {
  name: 'roleTree',
  components: { MenuAction, MenuActionItem, PositionAdd, PositionEdit },
  emits: ['select', 'initSelect'],
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const deleteConfirm = useDeleteConfirm()
    const treeRef = ref(null)
    const store = useStore()

    const state = reactive({
      // 用于刷新tree
      show: true,
      showEdit: false,
      showAdd: false,
      defaultTreeData: [
        {
          id: 'default',
          pid: '',
          name: '默认',
          roleComponentId: 'AdminList',
          childList: [
            {
              id: 'admin-list',
              pid: 'default',
              name: '主管理员',
              roleComponentId: 'AdminList',
              childList: []
            },
            {
              id: 'sub-admin-list',
              pid: 'default',
              name: '子管理员',
              roleComponentId: 'SubAdminList',
              childList: []
            },
            {
              id: 'manager-list',
              pid: 'default',
              name: '主管/上级',
              roleComponentId: 'ManagerList',
              childList: []
            }
          ]
        },
        {
          id: 'position',
          pid: '',
          name: '岗位',
          roleComponentId: 'PositionList',
          childList: []
        }
      ],
      treeDefaultExpand: ['default', 'position'],
      current: {},
      swaping: false
    })
    const onCurrentChange = (data, node) => {
      if (state.current.id === data.id) return false
      state.current = data
      emit('select', data.id, data, node)
    }
    const addPosition = () => {
      console.log('add Position Click')
      state.showAdd = true
    }
    // 交换节点位置
    const swap = (type, data, node) => {
      const nodeList = node.parent.childNodes
      const index = nodeList.findIndex(item => item === node)
      if (type === 'up') {
        const preNode = nodeList[index - 1]
        if (state.swaping && !preNode) return false
        // treeRef.value.remove(node)
        // treeRef.value.insertBefore(node.data, preNode)
        // treeRef.value.setCurrentKey(node.data.id)
        doUpdatePositionSort(node, preNode)
      } else {
        const nextNode = nodeList[index + 1]
        if (state.swaping && !nextNode) return false
        // treeRef.value.remove(node)
        // treeRef.value.insertAfter(node.data, nextNode)
        // treeRef.value.setCurrentKey(node.data.id)
        doUpdatePositionSort(node, nextNode)
      }
      // TODO 提交后台修改
    }
    // 操作点击事件
    const onActionClick = async (type, data, node) => {
      switch (type) {
        case 'edit':
          state.showEdit = true
          break
        case 'delete':
          doPositionDelete(data, node)
          break
        case 'up':
          swap(type, data, node)
          break
        case 'down':
          swap(type, data, node)
          break
      }
    }
    // 更新岗位角色位置
    const doUpdatePositionSort = async (currentNode, node) => {
      if (!currentNode && !node) return false
      const params = {
        data: [
          {
            id: currentNode.data.id,
            sort: node.data.sort
          },
          {
            id: node.data.id,
            sort: currentNode.data.sort
          }
        ]
      }
      state.swaping = true
      await updatePositionSort(params)
      state.current = currentNode.data
      await positionList()
      state.swaping = false
    }
    // 删除岗位角色
    const doPositionDelete = async (data, node) => {
      try {
        let nodeList = node.parent.childNodes
        let index = nodeList.findIndex(item => item === node)

        if (await deleteConfirm('您确认要删除该岗位吗?')) {
          const result = await deletePosition(data.id)
          if (result) {
            console.log('删除成功')
            if (nodeList.length == 1) {
              state.current = state.defaultTreeData[0].childList[0]
              emit('initSelect', state.defaultTreeData[0].childList[0])
              positionList()
            } else {
              if (index < nodeList.length - 1) {
                const nextNode = nodeList[index + 1]
                state.current = nextNode.data
                emit('select', nextNode.data.id, nextNode.data, nextNode)
                positionList()
              } else {
                if (index == nodeList.length - 1) {
                  const preNode = nodeList[index - 1]
                  state.current = preNode.data
                  emit('select', preNode.data.id, preNode.data, preNode)
                  positionList()
                }
              }
            }
          }
        }
      } catch (error) {
        if (0 === error.code) {
          ElMessageBox.alert(error.message, '提示', {
            confirmButtonText: '我知道了'
          })
        }
      }
    }
    // 判断是否可以向上移动
    const canMoveUp = node => {
      const parent = node.parent
      if (parent.childNodes[0] === node) {
        return false
      }
      return true
    }
    // 判断是否可以向下移动
    const canMoveDown = node => {
      const parent = node.parent
      const childLen = parent.childNodes.length
      if (parent.childNodes[childLen - 1] === node) {
        return false
      }
      return true
    }
    // 获取岗位列表
    const positionList = async () => {
      console.log('request positionList')
      try {
        let result = await getPositionList()
        let data = result.data || []
        for (let i = 0; i < data.length; i++) {
          data[i].pid = 'position'
          data[i].roleComponentId = 'PositionList'
        }
        state.defaultTreeData[1].childList = data || []
        refreshTree()
      } catch (error) {
        console.log('[getPositionList]:Error', error)
      }
    }
    // 刷新树
    const refreshTree = () => {
      state.show = false
      nextTick(() => {
        state.show = true
      })
    }
    // 添加岗位成功事件
    const onAddPositionSuccess = name => {
      console.log(name)
      positionList()
    }
    // 编辑岗位成功事件
    const onEditPositionSuccess = name => {
      state.current.name = name
      emit('initSelect', state.current)
      positionList()
    }
    watch(
      () => store.state.currentRoleState,
      val => {
        if (!val) {
          //编辑弹框关闭后跟新调用
          positionList()
        }
      },
      { immediate: true }
    )
    onMounted(() => {
      // positionList()
      emit('initSelect', state.defaultTreeData[0].childList[0])
      state.current = state.defaultTreeData[0].childList[0]
    })
    return {
      state,
      treeRef,
      onCurrentChange,
      addPosition,
      canMoveDown,
      canMoveUp,
      onActionClick,
      onAddPositionSuccess,
      onEditPositionSuccess
    }
  }
}
</script>
<style lang="scss" scoped>
@import './tree.scss';
</style>

<template>
  <el-drawer
    :title="`${employeeInfo.name || ''} ID：${employeeInfo.id || ''}`"
    :before-close="handleClose"
    v-model="state.show"
    custom-class="drawer-info"
    ref="drawer"
    size="400px"
  >
    <div class="drawer-content">
      <el-tabs v-model="state.activeName" @tab-click="handleClick">
        <el-tab-pane label="个人资料" name="first">
          <div class="form-wrap-box">
            <div class="form-wrap">
              <el-form
                :model="form"
                ref="employeeFormRef"
                :rules="rules"
                inline-message
                size="small"
                label-position="right"
                label-width="80px"
                disabled
              >
                <el-form-item label="头像：" class="label-upload-image">
                  <el-upload
                    class="avatar-uploader"
                    action="/"
                    :accept="settings.accept"
                    :before-upload="beforeUpload"
                    :http-request="httpRequest"
                    :show-file-list="false"
                  >
                    <div class="avatar-uploader-icon">
                      <img :src="form.profileUrl" alt="" />
                    </div>
                  </el-upload>
                </el-form-item>
                <el-form-item label="姓名:" required prop="username">
                  <el-input v-model="form.username" maxlength="20" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="别名:" prop="alias">
                  <el-input v-model="form.aliasName" autocomplete="off" maxlength="40" disabled></el-input>
                </el-form-item>

                <el-form-item prop="account">
                  <template #label>
                    <span>账号</span>
                    <el-tooltip placement="top">
                      <template #content><span class="input-tip">此账号由系统生成，只允许修改一次</span></template>
                      <i class="iconfont icon-help"></i>
                    </el-tooltip>
                    :
                  </template>
                  <el-input
                    size="small"
                    v-model="form.account"
                    :disabled="employee.id && form.account ? true : false"
                    placeholder="成员唯一标识，设定以后不支持修改"
                  ></el-input>
                </el-form-item>
                <el-form-item label="性别&nbsp;:" prop="gender" class="radio-item">
                  <el-radio-group v-model="form.gender" disabled>
                    <el-radio label="1">男</el-radio>
                    <el-radio label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="dashed-border"></div>
                <el-form-item label="手机&nbsp;:" prop="mobile">
                  <el-input size="small" v-model="form.mobile" disabled></el-input>
                </el-form-item>
                <el-form-item prop="telphone" label="座机&nbsp;:">
                  <el-input v-model="form.telphone" size="small" disabled></el-input>
                </el-form-item>
                <el-form-item prop="email" label="邮箱&nbsp;:">
                  <el-input v-model="form.email" size="small" disabled></el-input>
                </el-form-item>
                <el-form-item prop="address" label="地址&nbsp;:">
                  <el-input v-model="form.address" size="small" disabled maxlength="60"></el-input>
                </el-form-item>
                <div class="dashed-border"></div>
                <el-form-item prop="departments" class="departments" label="部门&nbsp;:">
                  <div class="deparemtn-wrap">
                    <div class="department-item" v-for="department in form.departments" :key="department.id">
                      <span>{{ department.name }}</span>
                      <menu-action>
                        <!-- <menu-action-item @click.stop="onActionClick('setMain', department)">设为主部门</menu-action-item> -->
                        <!-- <menu-action-item
                          @click.stop="onActionClick('department-delete', department, index, form.departments)"
                        >
                          删除
                        </menu-action-item> -->
                      </menu-action>
                    </div>
                    <!-- <el-button type="text" size="small" @click="handleEditClick('department')">修改</el-button> -->
                  </div>
                </el-form-item>
                <!-- <el-form-item prop="label" label="标签&nbsp;:">
            <el-button type="text" size="small">修改</el-button>
          </el-form-item> -->
                <el-form-item prop="position" label="职务&nbsp;:">
                  <el-input v-model="form.position" maxlength="120" disabled></el-input>
                </el-form-item>
                <el-form-item prop="identity" label="身份&nbsp;:" class="radio-item">
                  <el-radio-group v-model="form.identity" disabled>
                    <el-radio label="0">普通成员</el-radio>
                    <el-radio label="1">上级</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="负责部门&nbsp;:" v-if="form.identity === '1'">
                  <div class="deparemtn-wrap">
                    <div class="department-item" v-for="department in form.dutyDepartmentList" :key="department.id">
                      <span>{{ department.label }}</span>
                      <menu-action>
                        <!-- <menu-action-item @click.stop="onActionClick('setMain', department)">设为主部门</menu-action-item> -->
                        <!-- <menu-action-item
                          @click.stop="
                            onActionClick('duty-department-delete', department, index, form.dutyDepartmentList)
                          "
                        >
                          删除
                        </menu-action-item> -->
                      </menu-action>
                    </div>
                    <!-- <el-button
                      type="text"
                      v-if="form.departments.length > 1"
                      size="small"
                      @click="handleEditClick('dutyDepartment')"
                    >
                      修改
                    </el-button> -->
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="actions-wrap">
              <!-- <el-button size="small" type="primary" @click="handleSave(false)">保存</el-button>
              <el-button size="small" type="danger" plain @click="handleDelete">删除</el-button> -->
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="员工画像" name="second">
          <!-- 每次都重新渲染 -->

          <iframe :src="iframeUrl" frameBorder="0" :style="{ width: '100%', height: 'calc(100vh - 155px)' }"></iframe>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
  <OrganizationSelect
    :filter="true"
    :config="state.config"
    @cancel="cancelOrgan"
    @confirm="onSelectDepartmentConfirm"
    :select_data="form.departmentSelects"
  />
</template>
<script setup>
import OrganizationSelect from '@/components/organization-select/index.vue'
import { reactive, defineProps, toRefs, watch, defineEmits, ref, computed } from 'vue'
import { useFileUpload } from '@/hooks/upload'
import { PROFILE_UPLOAD_HOLDER } from '@/common/const'
import { getFileShowUrl } from '@/common/utils'
// import { ElMessage } from 'element-plus'
import { phoneValidator, mobildValidator, emailValidator } from '@/common/validator'
// import MenuAction, { MenuActionItem } from '@/views/components/menu-action/index.vue'
import MenuAction from '@/views/components/menu-action/index.vue'
// import { saveEmployee, updateEmployee, delEmployee } from '@/apis'
// import { useDeleteConfirm } from '@/hooks/ui'
const props = defineProps({
  id: { type: String, required: true },
  modelValue: {
    type: Boolean,
    default: false
  },
  employee: {
    type: Object,
    default: () => {
      return {}
    }
  },
  department: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const emit = defineEmits(['update:modelValue', 'onConfirm'])
const handleRegValue = value => {
  let flag = false
  let reg = /^\w+$/
  for (let char of value) {
    if (!reg.test(char) && char != '·' && char != '·' && char != '-' && char != '@') {
      flag = true
      return flag
    }
  }
  return flag
}
const validatePass = (rule, value, callback) => {
  if (value && value.length < 4) callback(new Error('请输入账号长度不能小于4位数'))
  let reg = /^\w+$/
  if (!reg.test(value) && handleRegValue(value)) {
    callback(new Error('账号只是能有字母、数字(·-_@)组成'))
  }
  if (value == '') {
    callback(new Error('请输入账号'))
  }
  callback()
}
const rules = {
  username: [{ required: true, message: '请输入姓名' }],
  account: [{ validator: validatePass, required: true, trigger: ['blur', 'change'] }],
  gender: [{ required: true, message: '请选择性别' }],
  mobile: [
    { required: true, message: '请输入手机号码' },
    {
      validator: mobildValidator,
      trigger: ['blur', 'change']
    }
  ],
  telphone: [
    {
      validator: phoneValidator,
      trigger: ['blur', 'change']
    }
  ],
  email: [
    {
      validator: emailValidator,
      trigger: ['blur', 'change']
    }
  ]
}
// const deleteConfirm = useDeleteConfirm()
const { employee, department } = toRefs(props)
const employeeInfo = employee.value
const departmentInfo = department.value
const employeeFormRef = ref(null)
const state = reactive({
  activeName: 'first',
  show: true,
  usernameState: null,
  config: {
    show: false
  }
})
let departmentList = employeeInfo.departmentList || []

if (!departmentList.find(item => item.id === departmentInfo.id)) {
  if (Object.keys(departmentInfo).length > 0) {
    departmentList.unshift(departmentInfo)
  }
}
const form = reactive({
  profileUrl: employeeInfo.avatar || PROFILE_UPLOAD_HOLDER,
  profileId: '',
  username: employeeInfo.name || '',
  aliasName: employeeInfo.alias || '',
  account: employeeInfo.userid || '',
  gender: employeeInfo.gender?.id || '',
  mobile: employeeInfo.mobile || '',
  telphone: employeeInfo.telephone || '',
  email: employeeInfo.email || '',
  address: employeeInfo.address || '',
  departments: departmentList,
  label: '',
  position: employeeInfo.position || '',
  identity: employeeInfo.identity?.id || '',
  dutyDepartment: employeeInfo.dutyDepartment || [],
  dutyDepartmentList: employeeInfo.dutyDepartmentList || [],
  departmentSelects: [] //部门选中项
})

//获取负责部门最新的数据
// const setDutyDepartmentList = (origin, target) => {
//   let listData = []
//   let list = JSON.parse(JSON.stringify(origin))
//   list.forEach(item => {
//     if (target.length > 0) {
//       target.forEach(c => {
//         if (item.id === c) {
//           listData.push(item)
//         }
//       })
//     } else {
//       listData.push(item)
//     }
//   })
//   return listData
// }
// const validForm = () => {
//   return new Promise(resolve => {
//     employeeFormRef.value.validate(valid => {
//       if (valid) {
//         resolve(true)
//       } else {
//         resolve(false)
//       }
//     })
//   })
// }
// const resetForm = () => {
//   employeeFormRef.value.resetFields()
//   form.username = ''
//   form.aliasName = ''
//   form.profileUrl = ''
//   form.departments = []
//   form.dutyDepartment = []
//   form.dutyDepartmentList = []
//   state.usernameState = null
// }
// const validatorUserName = val => {
//   return !val ? '0' : null
// }
// 保存 goOn 是否继续
// const getParams = () => {
//   const departmentIds = []
//   const departmentNames = []
//   let dutyIds = []
//   let dutyNames = []
//   form.departments.forEach(item => {
//     departmentIds.push(item.id)
//     departmentNames.push(item.name)
//     if (form.dutyDepartmentList.length > 0) {
//       form.dutyDepartmentList.forEach(c => {
//         if (c.id === item.id) {
//           dutyNames.push(item.name)
//           dutyIds.push(item.id)
//         }
//       })
//     }
//   })
//   const params = {
//     avatar: form.profileUrl,
//     name: form.username,
//     userid: form.account,
//     alias: form.aliasName,
//     gender: form.gender,
//     mobile: form.mobile,
//     telephone: form.telphone,
//     email: form.email,
//     address: form.address,
//     departmentName: departmentNames,
//     department: departmentIds,
//     dutyDepartmentName: dutyNames,
//     dutyDepartment: dutyIds,
//     position: form.position,
//     identity: form.identity,
//     type: '1',
//     status: '2'
//   }
//   if (employeeInfo?.sort) {
//     params.sort = employeeInfo.sort || ''
//   }
//   return params
// }
// const handleSave = async goOn => {
//   const employeeId = props.employee?.id
//   state.usernameState = validatorUserName(form.username)
//   if (['0', '1'].includes(state.usernameState)) {
//     return
//   }
//   const valid = await validForm()
//   if (!valid) return
//   const params = getParams()

//   if (params.department.length === 0) {
//     ElMessage.warning('请选择部门')
//     return
//   }
//   let res = {}
//   if (employeeId) {
//     params['id'] = employeeId
//     res = await updateEmployee(params)
//   } else {
//     res = await saveEmployee(params)
//   }
//   if (res.isOk) {
//     ElMessage.success(employeeId ? '编辑成功' : '添加成功')
//     emit('success')
//   }
//   if (!goOn) {
//     // 关闭
//     emit('cancel')
//   } else {
//     // TODO 清空表单
//     resetForm()
//   }
// }

// 头像上传相关
const { settings, beforeUpload, httpRequest } = useFileUpload(
  file => {
    form.profileId = file.id
    form.profileUrl = getFileShowUrl(file)
  },
  { accept: '.png,.jpg', limit: 1, size: '10MB' }
)

// 部门action
// const onActionClick = (type, data, index, ary) => {
//   if (['duty-department-delete', 'department-delete'].includes(type)) {
//     ary.splice(index, 1)
//     if (type === 'department-delete') {
//       form.dutyDepartmentList = form.dutyDepartmentList.filter(c => c.id !== data.id)
//     }
//   }
// }
// const handleEditClick = async type => {
//   state.transferDialogType = type
//   if (type === 'dutyDepartment') {
//     form.departmentSelects = await form.dutyDepartmentList
//   } else {
//     form.departmentSelects = await form.departments
//   }
//   nextTick(() => {
//     state.config.show = true
//   })
// }
const onSelectDepartmentConfirm = list => {
  if (state.transferDialogType === 'dutyDepartment') {
    form.dutyDepartmentList = list
  } else {
    form.departments = list
  }
  state.config.show = false
}
const iframeUrl = computed(() => {
  return `${window.location.origin}/web-interior-app/#/employee-portrait/${employeeInfo.id}`
})
const cancelOrgan = () => {
  state.config.show = false
}
const handleClose = () => {
  emit('update:modelValue', false)
}
const handleClick = (tab, event) => {
  console.log(tab, event)
}
// 删除员工
// const handleDelete = async () => {
//   const confirm = await deleteConfirm('您确认要删除该信息吗?')
//   if (confirm) {
//     await delEmployee({ id: props.id, department: props.department.id })
//     ElMessage.success('删除成功')
//     emit('delete')
//   }
// }
watch(
  () => form.identity,
  val => {
    if (val === '1') {
      // let list = JSON.parse(JSON.stringify(form.departments))
      // form.dutyDepartmentList = setDutyDepartmentList(list, form.dutyDepartment)

      if (form.departments.length === 0) {
        form.identity = '0'
      }
    }
  },
  { immediate: true }
)
watch(
  () => form.departments,
  val => {
    if (val.length === 0) {
      form.identity = '0'
    } else {
      // let list = JSON.parse(JSON.stringify(val))
      // form.dutyDepartmentList = setDutyDepartmentList(list, form.dutyDepartment)
    }
  },
  { immediate: true, deep: true }
)
watch(
  () => props.modelValue,
  value => {
    state.show = value
  }
)
</script>
<style lang="scss" scoped>
.drawer-info {
  .drawer-content {
    box-sizing: border-box;
    padding: 0 20px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding-bottom: 40px;
    position: relative;
    box-sizing: border-box;
    padding: 0 20px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding-bottom: 40px;
    position: relative;
    .label-upload-image {
      :deep(.el-form-item__content) {
        line-height: 60px;
        height: 60px;
        .avatar-uploader {
          width: 60px;
          border: 1px dotted $border-color-d;
          border-radius: 4px;
          height: 60px;
          .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            &:hover {
              border-color: #409eff;
            }
          }
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 60px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .avatar {
          width: 60px;
          height: 60px;
          display: block;
        }
      }
    }
    // .drawer-footer {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   z-index: 123;
    // }
  }
  .input-tip {
    font-size: 12px;
    color: $text-secondary;
    margin-left: 10px;
  }
  .deparemtn-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .department-item {
      box-sizing: border-box;
      padding: 0 20px 0 12px;
      line-height: 28px;
      font-size: 14px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #dddddd;
      margin-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
      position: relative;
      span {
        white-space: nowrap;
      }
      .menu-action {
        top: 0;
        width: 20px;
        height: 100%;
        cursor: pointer;

        .menu-action__trigger {
          .iconfont {
            color: #bbbbbb;
          }
        }
        .menu-action__list {
          margin-top: 0;
        }
      }
    }
  }
  .actions-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top: 1px solid #eee;
    padding-top: 10px;
  }
  .dashed-border {
    margin-bottom: 10px;
  }
  :deep(.el-radio-group) {
  }
}
</style>

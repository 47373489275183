<template>
  <el-empty :image="image || state.emptyImage" :image-size="imageSize" :description="description"></el-empty>
</template>
<script>
import { reactive, onMounted } from 'vue'
import imgUrl from '@/assets/empty_employee.png'
export default {
  name: 'emptyEmployee',
  props: {
    image: {
      type: String,
      default: ''
    },
    imageSize: {
      type: Number,
      default: 0
    },
    description: {
      type: String,
      default: '当前角色无成员'
    }
  },
  setup() {
    const state = reactive({
      emptyImage: imgUrl
    })
    onMounted(() => {})
    return {
      state
    }
  }
}
</script>
<style lang="scss" scoped>
@import './sub-admin-list.scss';
</style>

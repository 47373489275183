<template>
  <div class="employee-list-wrap">
    <!-- 员工详情 -->
    <!-- <template v-if="showEmployeeDetail">
      <employee-info
        @delete="handleDeleteEmployee"
        @edit="handleEditEmployee"
        @back="showEmployeeDetail = false"
        :id="currentEmployee.id"
        :department="departmentInfo"
      ></employee-info>
    </template> -->
    <!-- 新员工详情 -->
    <template v-if="showEmployeeDetail">
      <EmployeeDrawer
        v-model="showEmployeeDetail"
        :department="departmentInfo"
        :employee="currentEmployee"
        :id="currentEmployee.id"
        @delete="handleDeleteEmployee"
        @cancel="showEmployeeDetail = false"
        @success="handleSaveEmployee"
      ></EmployeeDrawer>
    </template>
    <!-- 员工新增编辑 -->
    <!-- <template v-else-if="showEmployeeAddEdit">
      <employee-add-edit
        :department="departmentInfo"
        :employee="currentEmployee"
        @cancel="showEmployeeAddEdit = false"
        @success="handleSaveEmployee"
      ></employee-add-edit>
    </template> -->
    <!-- 员工列表 -->
    <!-- <template v-else> -->
    <div class="department-name">
      <span>{{ departmentInfo.name }}</span>
      <span>({{ count }}人)</span>
      <!-- <el-button type="default" v-if="departmentInfo.id !== '1'" round size="mini" @click="openEditDepartment">
          编辑
        </el-button> -->
    </div>
    <div class="filter-wrap">
      <!-- <template v-if="checkEmployeeSecret">
          <el-button type="primary" round size="mini" @click="openAddEmployee">添加成员</el-button>
          <el-button type="default" round size="mini" @click="goToImportExport">批量导入/导出</el-button>
          <el-button type="default" round size="mini" @click="openCheckedDepartment">调整部门</el-button>
          <el-button type="default" round size="mini" @click="sortTableList">调整顺序</el-button> //放开后继续注释
          <el-button type="default" round size="mini" @click="checkedDelete">删除</el-button>
        </template> -->
      <el-button type="default" round size="mini" @click="updateAddress">同步通讯录</el-button>
    </div>
    <div class="table-wrapper">
      <div class="el-table--fit el-table" style="width: 100%">
        <div class="el-table__header-wrapper">
          <table border="0" cellpadding="0" cellspacing="0" class="el-table__header" style="width: 740px">
            <colgroup>
              <col width="60" />
              <col width="120" />
              <col width="270" />
              <col width="150" />
              <col width="140" />
              <col name="gutter" width="0" />
            </colgroup>
            <thead class="has-gutter">
              <tr class="custom-table-header">
                <th class="is-center" colspan="1" rowspan="1">
                  <div class="cell">
                    <el-checkbox v-model="checkedAll" @change="handleChangeTableCheckAll"></el-checkbox>
                  </div>
                </th>
                <th class="is-left" colspan="1" rowspan="1">
                  <div class="cell">姓名</div>
                </th>
                <th class="is-left" colspan="1" rowspan="1">
                  <div class="cell">部门</div>
                </th>
                <th class="is-left" colspan="1" rowspan="1">
                  <div class="cell">职务</div>
                </th>
                <th class="is-left" colspan="1" rowspan="1">
                  <div class="cell">手机</div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="sort-tip-wrap" v-if="showDraggableTip">
          <div class="text">上下移动员工调整位置</div>
          <span class="action" data-key="1" @click="saveSort">保存</span>
          <span class="action" data-key="2" @click="cancelSort">撤销</span>
        </div>
        <div class="el-table__body-wrapper is-scrolling-none employee-content-table" v-loading="loading">
          <table
            class="el-table__body"
            cellspacing="0"
            cellpadding="0"
            border="0"
            style="width: 740px"
            v-if="list.length"
          >
            <colgroup>
              <col width="60" />
              <col width="120" />
              <col width="270" />
              <col width="150" />
              <col width="140" />
            </colgroup>
            <Draggable v-model="list" tag="tbody" item-key="name" :disabled="disabledDraggable">
              <template #item="{ element }">
                <tr class="el-table__row employee-item" @click="checkedDetail(element)">
                  <td class="is-center" rowspan="1" colspan="1">
                    <div class="cell">
                      <el-checkbox
                        v-model="element.checked"
                        @click="
                          e => {
                            e.stopPropagation()
                          }
                        "
                        @change="handleChangeTableCheckItem(element)"
                      ></el-checkbox>
                    </div>
                  </td>
                  <td rowspan="1" colspan="1">
                    <div class="cell" style="width: 118px">
                      <span>{{ element.name }}</span>
                    </div>
                  </td>
                  <td rowspan="1" colspan="1">
                    <div class="cell">
                      <span>{{ element.departmentName?.join('、') }}</span>
                    </div>
                  </td>
                  <td rowspan="1" colspan="1">
                    <div class="cell">
                      <span>{{ element.position }}</span>
                    </div>
                  </td>
                  <td rowspan="1" colspan="1">
                    <div class="cell">
                      <span>{{ element.mobile }}</span>
                    </div>
                  </td>
                </tr>
              </template>
            </Draggable>
          </table>
          <div class="el-table__empty-block" style="height: 100%; width: 740px" v-else>
            <span class="el-table__empty-text">暂无数据</span>
          </div>
        </div>
      </div>
    </div>
    <pagination
      @page-change="handleChangePageNo"
      v-model="query.pageNo"
      :page-size="query.pageSize"
      @size-change="handleChangePageSize"
      :total="count"
    />
    <!-- </template> -->
    <!-- 选择部门弹窗 -->
    <!-- <DepartmentSelect ref="departmentSelectRef" :showTrigger="false" @ok="confirmCheckedDepartment" /> -->
    <OrganizationSelect
      :filter="true"
      :config="config"
      @cancel="cancelOrgan"
      @confirm="confirmOrgan"
    ></OrganizationSelect>
  </div>
</template>
<script>
import { ElMessageBox, ElMessage } from 'element-plus'
import Draggable from 'vuedraggable'
import { reactive, onUnmounted, watch, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { getEmployeeList, batchChangeEmployeeDepartment, batchDelEmployee, sortEmployee, syncDeptAndEmp } from '@/apis'
// import EmployeeInfo from './employee-info.vue'
import EmployeeDrawer from './employee-drawer.vue'
// import EmployeeAddEdit from './employee-edit.vue'
import OrganizationSelect from '@/components/organization-select/index.vue'
// import DepartmentSelect from '@/components/department-select/index.vue'
import Pagination from '@/components/pagination'
import { debounce } from '@/common/utils'
export default {
  name: 'employeeList',
  components: { OrganizationSelect, Pagination, Draggable, EmployeeDrawer },
  props: {
    departmentInfo: {
      type: Object,
      required: true,
      default: () => ({})
    },
    // 企业是否配置员工密钥， 未配置 不能编辑
    checkEmployeeSecret: {
      type: Boolean,
      default: false
    }
  },
  emits: ['openEditDepartment'],
  setup(props, { emit }) {
    const router = useRouter()
    // const departmentSelectRef = ref(null)
    const state = reactive({
      list: [],
      backupList: [],
      count: 0,
      loading: false,
      query: {
        pageSize: 10,
        pageNo: 1,
        name: '',
        mobile: '',
        department: '',
        identity: '',
        positionNos: ''
      },
      disabledDraggable: true,
      showDraggableTip: false,
      showEmployeeDetail: false,
      showEmployeeAddEdit: false,
      checkedAll: false,
      checkedList: [],
      currentEmployee: {},
      config: {
        show: false
      }
    })
    // 分页查询列表
    const queryPageList = async () => {
      state.loading = true
      const result = await getEmployeeList(state.query)

      const { list, count } = result.data || {}
      state.list = list || []
      state.count = count || 0
      state.loading = false
      if (state.list.length > 0) {
        state.list.map(item => (item.checked = false))
      }
    }
    // 切换pageNo
    const handleChangePageNo = pageNo => {
      state.query.pageNo = pageNo
      state.checkedAll = false
      queryPageList()
    }
    // 切换pageSize
    const handleChangePageSize = size => {
      state.query.pageSize = size
      state.query.pageNo = 1
      state.checkedAll = false
      queryPageList()
    }
    // 点击调整部门
    const openCheckedDepartment = () => {
      if (!state.checkedList.length) {
        ElMessage.warning('请选择数据')
        return false
      }
      state.config.show = true
      // departmentSelectRef.value.state.selectList=
      // departmentSelectRef.value.triggerDialog(true)
    }
    // 调整部门确认
    const confirmCheckedDepartment = async (ids, list) => {
      if (!ids || !ids.length) return false
      const departmentIds = ids
      const departmentName = list.map(item => item.name)
      const userIds = state.checkedList.map(item => item.id)
      const userList = state.checkedList.map(item => item.userid)
      const params = {
        checkedList: userIds,
        department: departmentIds,
        departmentName: departmentName,
        userList: userList
      }

      await batchChangeEmployeeDepartment(params)
      ElMessage.success('调整部门成功')
      queryPageList()
    }
    // 点击添加员工
    const openAddEmployee = () => {
      state.showEmployeeAddEdit = true
      state.currentEmployee = {}
    }
    // 点击导入导出
    const goToImportExport = () => {
      router.push({ path: '/import/user', query: { depart: props.departmentInfo.id } })
    }
    // 点击调整顺序
    const sortTableList = () => {
      state.showDraggableTip = true
      state.disabledDraggable = false
      state.backupList = [...state.list]
    }
    // 保存排序
    const saveSort = async () => {
      const maxSort = Math.max(...state.list.map(item => item.sort))

      const list = state.list.map((item, index) => ({ id: item.id, sort: maxSort - index }))
      const params = { data: list }
      const res = await sortEmployee(params)
      if (res.isOk) {
        ElMessage.success('排序成功')
        state.showDraggableTip = false
        state.disabledDraggable = true
        queryPageList()
      }
    }
    // 撤销排序
    const cancelSort = () => {
      state.showDraggableTip = false
      state.disabledDraggable = true
      state.list = [...state.backupList]
    }
    // 点击删除
    const checkedDelete = () => {
      if (!state.checkedList.length) {
        return ElMessage.warning('请选择数据')
      }
      ElMessageBox.confirm('您确认要删除所选数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const ids = state.checkedList.map(item => item.id)
        await batchDelEmployee({ ids: [...ids], department: props.departmentInfo.id }).then(res => {
          if (res.code === 200) {
            ElMessage.success('删除成功')
          }
        })
        queryPageList()
      })
    }
    // 选择表格项
    const handleChangeTableCheckItem = () => {
      state.checkedList = state.list.filter(item => item.checked === true)
      state.checkedAll = !state.list.some(item => !item.checked)
    }
    // 全选表格项
    const handleChangeTableCheckAll = isCheckedAll => {
      state.list.forEach(item => {
        item.checked = isCheckedAll
      })
      state.checkedList = state.list.filter(item => item.checked === true)
    }
    // 点击查看成员详情
    const checkedDetail = async item => {
      if (!state.disabledDraggable) return
      //
      state.currentEmployee = item
      state.showEmployeeDetail = true
      // 出现详情
    }
    // 详情页点击删除成员
    const handleDeleteEmployee = () => {
      state.showEmployeeDetail = false
      queryPageList()
    }
    // 详情页点击编辑成员
    const handleEditEmployee = user => {
      state.showEmployeeDetail = false
      state.showEmployeeAddEdit = true
      state.currentEmployee = user
    }
    // 保存成员成功
    const handleSaveEmployee = () => {
      state.showEmployeeDetail = false
      state.showEmployeeAddEdit = false
      queryPageList()
    }
    // 监听属性departmentId变化更新
    const stop = watch(
      () => props.departmentInfo.id,
      departmentId => {
        state.query.pageNo = 1
        state.query.department = departmentId
        if (departmentId) {
          queryPageList()
        }
        state.checkedAll = false
        state.checkedList = []
      },
      { immediate: true }
    )
    //编辑部门
    const openEditDepartment = () => {
      emit('openEditDepartment', { type: 'employee-list', data: props.departmentInfo })
    }
    //同步通讯录
    const updateAddress = debounce(() => {
      state.loading = true

      syncDeptAndEmp()
        .then(res => {
          state.loading = false
          if (res.code === 200) {
            ElMessage.success('同步成功')
            emit('editDepartmentUpdate', props.departmentInfo)
            queryPageList()
          }
        })
        .catch(() => {
          state.loading = false
        })
    }, 1000)
    const cancelOrgan = () => {
      state.config.show = false
    }
    const confirmOrgan = async list => {
      if (!list || !list.length) return false
      const departmentIds = list.map(item => item.id)
      const departmentName = list.map(item => item.name)
      const userIds = state.checkedList.map(item => item.id)
      const userList = state.checkedList.map(item => item.userid)
      const params = {
        checkedList: userIds,
        department: departmentIds,
        departmentName: departmentName,
        userList: userList
      }
      await batchChangeEmployeeDepartment(params)
      state.config.show = false
      ElMessage.success('调整部门成功')
      queryPageList()
    }
    onUnmounted(() => {
      stop()
    })
    return {
      ...toRefs(state),
      handleChangePageNo,
      handleChangePageSize,
      handleChangeTableCheckItem,
      handleChangeTableCheckAll,
      checkedDelete,
      checkedDetail,
      // departmentSelectRef,
      openCheckedDepartment,
      confirmCheckedDepartment,
      openAddEmployee,
      sortTableList,
      goToImportExport,
      handleEditEmployee,
      handleDeleteEmployee,
      handleSaveEmployee,
      saveSort,
      cancelSort,
      openEditDepartment,
      updateAddress,
      cancelOrgan,
      confirmOrgan
    }
  }
}
</script>
<style lang="scss" scoped>
@import './employee-list.scss';
</style>

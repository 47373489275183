<template>
  <div class="sub-admin-list" v-loading="!state.loaded">
    <div class="sub-admin-name">{{ name }}({{ state.count }}人)</div>
    <div class="alert-wrap">
      <div class="text">“子管理员”属于特殊角色，暂时无法在通讯录编辑， 请到 “设置－设置子管理员” 设置</div>
      <button class="m-button" @click="settingClick">设置子管理员</button>
    </div>
    <template v-if="state.list.length">
      <div class="table-wrap">
        <el-table :data="state.list" :max-height="maxTableHeight">
          <el-table-column prop="name" label="姓名" width="180"></el-table-column>
          <el-table-column prop="department" label="部门">
            <template #default="scope">
              <el-popover placement="right-end" popper-class="dark-popover" :width="220" trigger="hover">
                <div>
                  <template v-for="(item, index) in scope.row.departmentName" :key="item">
                    <span type="departmentName" :openid="item">{{ item }}</span>
                    <template v-if="index !== scope.row.departmentName.length - 1">，</template>
                  </template>
                </div>
                <template #reference>
                  <div class="ellipsis column-popover">
                    <template v-for="(item, index) in scope.row.departmentName" :key="item">
                      <span type="departmentName" :openid="item">{{ item }}</span>
                      <template v-if="index !== scope.row.departmentName.length - 1">，</template>
                    </template>
                  </div>
                </template>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination-block
        v-if="state.list?.length"
        @page-change="handleCurrentChange"
        v-model="state.pageNo"
        :page-size="state.pageSize"
        @size-change="handleSizeChange"
        :total="state.count"
      ></pagination-block>
    </template>
    <empty-employee v-else></empty-employee>
  </div>
</template>
<script>
import { reactive } from 'vue'
import EmptyEmployee from './empty.vue'
import { useRouter } from 'vue-router'
import { getChildRoleList } from '@/apis'
import PaginationBlock from '@/components/pagination'
export default {
  name: 'subAdminList',
  components: { EmptyEmployee, PaginationBlock },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    maxTableHeight: {
      type: Number,
      default: 100
    }
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      loaded: false,
      list: [],
      pageNo: 1,
      pageSize: 10
    })
    const initList = async () => {
      try {
        const params = {
          pageNo: state.pageNo,
          pageSize: state.pageSize
        }
        const { data } = await getChildRoleList(params)
        state.list = data?.list || []
        state.count = data?.count || 0
      } catch (error) {
        console.log('initList error', error)
      } finally {
        state.loaded = true
      }
    }
    initList()
    const handleCurrentChange = page => {
      state.pageNo = page
      initList()
    }
    const handleSizeChange = size => {
      state.pageSize = size
      state.pageNo = 1
      initList()
    }
    const settingClick = () => {
      router.push({ name: 'settingAccountChild' })
    }
    return {
      state,
      settingClick,
      handleCurrentChange,
      handleSizeChange
    }
  }
}
</script>
<style lang="scss" scoped>
@import './sub-admin-list.scss';
</style>

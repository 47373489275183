<template>
  <div class="admin-list-wrap" v-loading="!state.loaded">
    <div class="admin-name">{{ name }}({{ state.list.length }}人)</div>
    <div class="alert-wrap">
      <div class="text">“主管理员”为默认角色， 请到“设置－更改主管理员”， 设置完成后自动同步</div>
      <button class="m-button" @click="settingClick">更换主管理员</button>
    </div>
    <div class="table-wrap">
      <el-table :data="state.list" :max-height="maxTableHeight">
        <el-table-column prop="name" label="姓名" width="180"></el-table-column>
        <el-table-column prop="department" label="部门">
          <template #default="scope">
            <el-popover placement="right-end" popper-class="dark-popover" :width="220" trigger="hover">
              <div>
                <template v-for="(item, index) in scope.row.departmentName" :key="item">
                  <span type="departmentName" :openid="item">{{ item }}</span>
                  <template v-if="index !== scope.row.departmentName.length - 1">，</template>
                </template>
              </div>
              <template #reference>
                <div class="ellipsis column-popover">
                  <template v-for="(item, index) in scope.row.departmentName" :key="item">
                    <span type="departmentName" :openid="item">{{ item }}</span>
                    <template v-if="index !== scope.row.departmentName.length - 1">，</template>
                  </template>
                </div>
              </template>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { getAdminList } from '@/apis'
export default {
  name: 'adminList',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    maxTableHeight: {
      type: Number,
      default: 100
    }
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      loaded: false,
      list: []
    })
    const initAdminList = async () => {
      try {
        const result = await getAdminList()
        state.list = result.data || []
      } catch (error) {
        console.log('error', error)
      } finally {
        state.loaded = true
      }
    }
    initAdminList()
    const settingClick = () => {
      router.push({ name: 'settingAccountMain' })
    }
    return {
      state,
      settingClick
    }
  }
}
</script>
<style lang="scss" scoped>
@import './admin-list.scss';
</style>

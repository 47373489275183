<template>
  <el-dialog width="450px" title="编辑岗位" append-to-body v-model="innerVisible" custom-class="custom-dialog">
    <div class="form-wrap">
      <el-form size="small" :model="form">
        <el-form-item label="岗位名称">
          <el-input v-model="form.positionName"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="edit-footer">
        <el-button type="primary" size="small" :loading="form.loading" @click="saveClick">保存</el-button>
        <el-button size="small" @click="cancel">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { reactive, onMounted, computed, watch } from 'vue'
import { updatePosition } from '@/apis'
import { useStore } from 'vuex'
import * as TYPE from '@/store/types'
export default {
  name: 'positionEdit',
  props: {
    visible: { type: Boolean, required: true },
    id: { type: String, required: true },
    name: { type: String, required: true },
    createTime: { type: String, required: true },
    sort: { type: Number, required: true }
  },
  emits: ['update:visible', 'success'],
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const store = useStore()
    const innerVisible = computed({
      get() {
        return props.visible
      },
      set(val) {
        emit('update:visible', val)
      }
    })
    const form = reactive({
      loading: false,
      positionName: ''
    })
    const saveClick = async () => {
      if (!props.id || !form.positionName || !props.createTime) return false
      form.loading = true
      const result = await updatePosition({
        id: props.id,
        name: form.positionName,
        createTime: props.createTime,
        sort: props.sort
      })
      form.loading = false
      if (result) {
        emit('update:visible', false)
        emit('success', form.positionName)
        store.commit(TYPE.CURRENT_ROLE_STATE, false)
      }
    }
    const cancel = () => {
      console.log(9999)
      store.commit(TYPE.CURRENT_ROLE_STATE, false)
      emit('update:visible', false)
    }
    watch(
      () => props.name,
      name => {
        form.positionName = name
      },
      { immediate: true }
    )
    onMounted(() => {})
    return {
      innerVisible,
      form,
      saveClick,
      cancel
    }
  }
}
</script>
<style lang="scss" scoped>
.form-wrap {
  padding: 10px 40px 0;
}
.edit-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    width: 94px;
    margin: 0;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}
</style>

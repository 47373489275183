// 身份证号码正则
const IDCARD_NUMBER_REG =
  /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
export const IDCardNumberValidator = (rule, value, callback) => {
  if (!value) return callback() // 没有值的时候不做验证
  if (!IDCARD_NUMBER_REG.test(value)) {
    return callback('请输入正确的身份证号码')
  }
  callback()
}

// 企业信用代码规则验证
const CREDIT_CODE_REG = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/
export const creditCodeValidator = (rule, value, callback) => {
  if (!value) return callback() // 没有值的时候不做验证
  if (CREDIT_CODE_REG.test(value)) return callback()
  callback(rule.message || '企业信用代码格式错误')
}

// 手机号码正则
const MOBILE_REG = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
export const mobildValidator = (rule, value, callback) => {
  if (!value) return callback() // 没有值的时候不做验证
  if (MOBILE_REG.test(value)) return callback()
  return callback(rule.message || '手机号码格式不正确')
}
// 座机，固定电话正则
const PHONE_REG = /^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/
export const phoneValidator = (rule, value, callback) => {
  if (!value) return callback() // 没有值的时候不做验证
  if (PHONE_REG.test(value)) return callback()
  callback(rule.message || '电话格式不正确')
}
// 手机或者固定电话正则表达式
export const mobileAndPhoneValidator = (rule, value, callback) => {
  if (!value) return callback()
  if (value.indexOf('-') !== -1 && PHONE_REG.test(value)) return callback()
  if (PHONE_REG.test(value) || MOBILE_REG.test(value)) return callback()
  callback(rule.message)
}

// 邮箱正则表达式
const EMAIL_REG = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
export const emailValidator = (rule, value, callback) => {
  if (!value) return callback() // 没有值的时候不做验证
  if (EMAIL_REG.test(value)) return callback()
  callback('邮箱格式不正确')
}
// 银行卡号
const BANK_NUMBER_REG = /^([1-9]{1})(\d{14}|\d{18})$/
export const bankNumberValidator = (rule, value, callback) => {
  if (!value) return callback()
  if (BANK_NUMBER_REG.test(value)) return callback()
  callback(rule.message || '银行卡号不正确')
}

// 域名地址正则匹配
const URL_REG = /^([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}(\.[a-zA-Z]{2,3}){1,2}$/
export const urlValidator = (rule, value, callback) => {
  if (!value || URL_REG.test(value)) return callback()
  callback(rule.message || 'url格式不正确')
}

// 微信号
const WECHAT_REG = /^[a-zA-Z0-9][-_a-zA-Z0-9]{5,19}$/
export const wechatValidator = (rule, value, callback) => {
  if (!value || WECHAT_REG.test(value)) return callback()
  callback(rule.message || '微信号格式不正确')
}

<template>
  <el-dialog
    width="450px"
    :title="departement?.id ? '编辑部门' : '新增部门'"
    append-to-body
    v-model="innerVisible"
    custom-class="custom-dialog"
  >
    <div class="form-wrap">
      <el-form
        size="small"
        ref="editFormRef"
        :hide-required-asterisk="true"
        :rules="rules"
        :model="form"
        label-position="top"
      >
        <el-form-item label="部门名称" prop="departmentName">
          <el-input v-model="form.departmentName" placeholder="请填写部门名称" maxlength="32"></el-input>
        </el-form-item>
        <el-form-item label="上级部门" prop="parentDepartment">
          <department-select
            :single="true"
            :disabled="departementAddState"
            @ok="handleOk"
            v-model="form.parentDepartment"
            placeholder="请选择部门"
          ></department-select>
        </el-form-item>
        <!-- <el-form-item label="设置主管/上级" prop="admin">
          <user-select v-model="form.admin" @ok="handleUserOk" placeholder="请选择主管"></user-select>
        </el-form-item> -->
      </el-form>
    </div>
    <template #footer>
      <div class="edit-footer">
        <el-button type="primary" size="small" @click="onSubmit">保存</el-button>
        <el-button size="small" @click="onCancel">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { computed, defineComponent, nextTick, onUnmounted, reactive, ref, watch } from 'vue'
import DepartmentSelect from '@/components/department-select/index.vue'
// import UserSelect from '@/components/user-select/index.vue'
import { saveDepartment, updateDepartment } from '@/apis/department'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'departmentEdit',
  components: { DepartmentSelect },
  props: {
    visible: { type: Boolean, required: true },
    departement: { type: Object, default: () => ({}) },
    departementAddState: { type: Boolean, default: false }
  },
  emits: ['update:visible', 'success'],
  setup(props, { emit }) {
    const innerVisible = computed({
      get() {
        return props.visible
      },
      set(val) {
        emit('update:visible', val)
      }
    })
    const editFormRef = ref(null)
    const form = reactive({
      departmentName: '',
      parentDepartment: ['1'],
      parentIds: [], // 所有父级
      admin: [], // 主管
      adminNames: [] // 主管名称
    })
    const rules = {
      departmentName: { required: true, message: '请输入部门名称', trigger: ['blue', 'change'] },
      parentDepartment: { required: true, message: '请选择上级部门', trigger: ['blue', 'change'] }
    }
    const stop = watch(
      () => props.departement,
      val => {
        console.log('val: ', val)
        form.departmentName = val?.name
        form.parentDepartment = (val && val.parent_id && [val?.parent_id]) || ['1']
        form.admin = val?.employeeIds || []
      },
      { immediate: true }
    )

    const editDepartment = async params => {
      const res = await updateDepartment(params)
      if (res.isOk) {
        ElMessage.success('编辑部门成功')
        emit('success', params)
        innerVisible.value = false
      }
    }
    const addDepartment = async params => {
      const res = await saveDepartment(params)
      if (res.isOk) {
        ElMessage.success('新增部门成功')
        emit('success')
        innerVisible.value = false
      }
    }
    const handleOk = (ids, list) => {
      const [item] = list || []
      if (item) {
        form.parentIds = [...item.parent_ids]
      }
    }
    const handleUserOk = (ids, users) => {
      form.adminNames = users.map(item => item.label)
    }
    const onSubmit = () => {
      editFormRef.value.validate(valid => {
        if (valid) {
          if (props.departement.id) {
            const params = { ...props.departement }
            params['name'] = form.departmentName
            params['parent_id'] = form.parentDepartment[0]
            params['parent_ids'] = form.parentIds
            params['employeeIds'] = form.admin
            params['employeeNames'] = form.adminNames
            editDepartment(params)
          } else {
            const params = { sort: 30 }
            params['name'] = form.departmentName
            params['parent_id'] = form.parentDepartment[0]
            params['parent_ids'] = form.parentIds
            params['employeeIds'] = form.admin
            params['employeeNames'] = form.adminNames
            addDepartment(params)
          }
        }
      })
    }
    const onCancel = () => {
      nextTick(() => {
        editFormRef.value.resetFields()
      })
      innerVisible.value = false
    }
    onUnmounted(() => {
      typeof stop === 'function' && stop()
    })
    return { innerVisible, form, rules, editFormRef, onSubmit, handleOk, handleUserOk, onCancel }
  }
})
</script>
<style lang="scss" scoped>
.form-wrap {
  padding: 10px 40px 0;
  :deep(.el-form) {
    .el-form-item__label {
      height: 32px;
    }
  }
}
.user-select .trigger .placeholder {
  color: #bbb;
}
.edit-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    width: 94px;
    margin: 0;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}
</style>
<style lang="scss">
.custom-dialog {
  .user-select .trigger .placeholder {
    color: #bbb !important;
  }
}
</style>

<template>
  <div class="address-list-wrapper">
    <div class="left-menu" ref="letMenuRef">
      <div class="search-wrap">
        <el-input
          size="mini"
          @input="onSearchInput"
          @keyup.enter="onSearchInput"
          @clear="onSearchInput"
          v-model.trim="searchState.searchText"
          clearable
          placeholder="搜索成员、部门"
        >
          <template #prefix>
            <i class="el-input__icon el-icon-search"></i>
          </template>
        </el-input>
        <!-- <div class="add-icon" v-if="state.checkEmployeeSecret">
          <i class="el-icon-circle-plus-outline" @click="handleAddDepartment"></i>
        </div> -->
      </div>
      <!-- 如果存在搜索结果 -->
      <div class="search-result" v-if="searchState.searched">
        <template v-if="searchState.employeeList.length || searchState.departmentList.length">
          <div class="group" v-if="searchState.employeeList.length">
            <div class="group-name">员工</div>
            <div
              class="employee-item"
              @click="searchClick(1, item)"
              :class="{ active: searchState.currentItem.id === item.id }"
              v-for="item in searchState.employeeList"
              :key="item.id"
            >
              <div class="name ellipsis">{{ item.name }}</div>
              <div class="department ellipsis">{{ item.departmentName.join(',') }}</div>
            </div>
          </div>
          <div class="group" v-if="searchState.departmentList.length">
            <div class="group-name">部门</div>
            <div
              class="department-item"
              @click="searchClick(2, item)"
              :class="{ active: searchState.currentItem.id === item.id }"
              v-for="item in searchState.departmentList"
              :key="item.id"
            >
              <div class="icon">
                <i class="iconfont icon-folder"></i>
              </div>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
        </template>
        <div class="no-match" v-else>若搜索不到结果，请转译再搜索</div>
      </div>
      <!-- 否则展示数据结构 -->
      <div class="tabs-wrapper" v-else>
        <div class="tabs-header">
          <el-radio-group size="mini" v-model="state.type" @change="onRadioGroupChange">
            <el-radio-button label="1">组织架构</el-radio-button>
            <el-radio-button label="2">角色</el-radio-button>
          </el-radio-group>
        </div>
        <div class="tab-content custom-bar" v-loading="state.treeLoadState">
          <!-- 组织结构 -->
          <div v-show="state.type == '1'">
            <department-tree
              ref="departmentTreeRef"
              @select="onDepartmentSelect"
              :hasEdit="state.checkEmployeeSecret"
            ></department-tree>
          </div>
          <!-- 角色结构 -->
          <role-tree
            v-show="state.type == '2'"
            ref="roleTreeRef"
            @select="onRoleSelect"
            @init-Select="onRoleInitSelect"
          ></role-tree>
        </div>
      </div>
    </div>
    <div class="container" id="addressBookContainer">
      <!-- 搜索相关展示 -->
      <template v-if="searchState.searched && searchState.selectType === 1">
        <empty-page
          description="暂无数据，您可以转译通讯录后再试试"
          v-if="!searchState.employeeList.length && !searchState.departmentList.length"
        ></empty-page>
        <search-user v-else :list="searchState.currentPerson"></search-user>
      </template>
      <template v-else>
        <!-- 组织架构相关主体内容 -->
        <employee-list
          v-if="state.type == '1'"
          ref="employeeListRef"
          :departmentInfo="state.currentDepartmentOrigin"
          :checkEmployeeSecret="state.checkEmployeeSecret"
          @openEditDepartment="openEditDepartment"
          @editDepartmentUpdate="editDepartmentUpdate"
        ></employee-list>
        <!-- 角色相关主体内容 -->
        <role-index
          v-if="state.type == '2'"
          :id="state.currentRole?.id || ''"
          :name="state.currentRole?.name || ''"
          @openEditDepartment="openEditDepartment"
          :current-component="state.currentRole?.roleComponentId"
        ></role-index>
      </template>
    </div>
  </div>
  <department-edit
    v-if="state.showAddDepart"
    @success="handleAddDepartSuccess"
    :departement="state.currentDepartment"
    v-model:visible="state.showAddDepart"
  ></department-edit>
  <position-edit
    v-if="showEdit"
    v-model:visible="showEdit"
    :id="state.currentRole?.id || ''"
    :name="state.currentRole?.name || ''"
    :create-time="state.currentRole?.createTime || ''"
    :sort="state.currentRole?.sort || 0"
  ></position-edit>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { defineComponent, reactive, ref, nextTick, watch, onUnmounted, computed } from 'vue'
import DepartmentTree from './department-tree.vue'
import EmployeeList from './employee-list.vue'
import RoleTree from './role/tree.vue'
import RoleIndex from './role/index.vue'
import PositionEdit from './role/position-edit.vue'
import DepartmentEdit from './departement-edit.vue'
import { searchEmployeeOrDepart, checkEmployeeSecretIsNull } from '@/apis'
import { debounce } from '@/common/utils'
import SearchUser from './search-user'
import EmptyPage from '@/views/components/empty-page'
import { useStore } from 'vuex'
export default defineComponent({
  components: {
    DepartmentTree,
    EmployeeList,
    RoleTree,
    RoleIndex,
    DepartmentEdit,
    SearchUser,
    EmptyPage,
    PositionEdit
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const tableRef = ref(null)
    const employeeListRef = ref(null)
    const departmentTreeRef = ref(null)
    // const roleTreeRef = ref()
    const state = reactive({
      searchText: '',
      type: '1',
      currentDepartment: {},
      currentDepartmentOrigin: {}, //备份当前选择部门节点
      currentRole: {},
      showAddDepart: false,
      treeLoadState: false, //左侧树形加载动画
      checkEmployeeSecret: false
    })
    // 查询当前企业是否配置员工密钥
    const checkEmployeeSecretFun = async () => {
      const result = await checkEmployeeSecretIsNull()
      state.checkEmployeeSecret = result.data
    }
    checkEmployeeSecretFun()
    /**************左侧树形相关*******************/
    const handleAddDepartment = () => {
      state.showAddDepart = true
      state.currentDepartment = {}
    }
    const handleAddDepartSuccess = () => {
      //部门修改成功回调
      state.showAddDepart = false
      departmentTreeRef.value.departmentEditSuccess()
    }
    // 部门选中事件
    const onDepartmentSelect = (id, data) => {
      state.currentDepartment = data
      nextTick(() => {
        //关闭员工：新增、编辑、查看页面
        if (employeeListRef.value) {
          employeeListRef.value.showEmployeeDetail = false
          employeeListRef.value.showEmployeeAddEdit = false
        }
      })
    }
    const showEdit = computed(() => {
      return store.state.currentRoleState
    })
    const stop = watch(
      () => route.query.type,
      type => {
        if (type === '1') {
          onDepartmentSelect(state.currentDepartment.id, state.currentDepartment)
        }
      }
    )
    watch(
      () => state.currentDepartment,
      val => {
        if (val && !state.showAddDepart) {
          state.currentDepartmentOrigin = JSON.parse(JSON.stringify(val))
        }
      }
    )
    onUnmounted(() => {
      stop && stop()
    })

    /**左侧角色树形相关 **/
    // 角色选中事件
    const onRoleSelect = (id, data, node) => {
      if (node.level > 1) {
        state.currentRole = data
      }
    }
    // 默认角色选中事件
    const onRoleInitSelect = data => {
      state.currentRole = data
    }
    // 选项变更事件
    const onRadioGroupChange = value => {
      router.replace(`/address-list?type=${value}`)
    }
    // onMounted(() => {
    //   state.type = route.query.type || '1'
    //   var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
    //   // 选择目标节点
    //   var target = document.querySelector('#addressBookContainer')
    //   var tabContent = document.querySelector('.tab-content')
    //   // 创建观察者对象
    //   var observer = new MutationObserver(() => {
    //     tabContent.style.height = `0px`
    //     tabContent.style.height = `${target.offsetHeight - 114}px`
    //   })
    //   // 配置观察选项:
    //   var config = {
    //     attributes: true,
    //     subtree: true
    //   }
    //   // 传入目标节点和观察选项
    //   observer.observe(target, config)
    // })

    // 搜索模块
    const callback = (type, item) => {
      // 如果是部门点击事件
      if (type === 2) {
        state.type == 1
        onDepartmentSelect(item.id, item)
      }
    }
    const openEditDepartment = object => {
      const { type, data } = object
      if (type === 'role') {
        state.showEdit = true
      } else {
        delete data.childList
        state.currentDepartment = data
        state.showAddDepart = true
      }
    }
    const editDepartmentUpdate = () => {
      handleAddDepartSuccess()
    }
    const { state: searchState, onSearchInput, searchClick } = useAddressListSearch(callback)

    return {
      state,
      tableRef,
      employeeListRef,
      departmentTreeRef,
      onDepartmentSelect,
      onRoleSelect,
      onRoleInitSelect,
      onRadioGroupChange,
      handleAddDepartment,
      handleAddDepartSuccess,
      searchState,
      onSearchInput,
      searchClick,
      openEditDepartment,
      editDepartmentUpdate,
      showEdit,
      checkEmployeeSecretIsNull
    }
  }
})
/**
 * 搜索相关
 */
function useAddressListSearch(fn) {
  const state = reactive({
    searchText: '',
    employeeList: [],
    departmentList: [],
    searched: false,
    currentItem: {},
    currentPerson: [],
    selectType: 1
  })
  const onSearchInput = debounce(() => {
    if (!state.searchText) {
      state.searched = false
    } else {
      handleSearch(state.searchText)
    }
  }, 300)
  const handleSearch = async name => {
    try {
      const result = await searchEmployeeOrDepart(name)
      const { data } = result || {}
      state.employeeList = data?.employees || []
      state.departmentList = data?.departments || []
      state.selectType = 1
      if (state.employeeList.length) {
        state.currentItem = state.employeeList[0]
        state.currentPerson = [state.employeeList[0]]
      } else if (state.departmentList.length) {
        state.selectType = 2
        state.currentItem = state.departmentList[0]
        typeof fn === 'function' && fn(state.selectType, state.currentItem)
      }
    } catch (error) {
      console.log('error: ', error)
    } finally {
      state.searched = true
    }
  }
  const searchClick = (type, item) => {
    state.currentItem = item
    state.selectType = type
    if (type === 1) {
      state.currentPerson = [item]
    } else {
      state.currentPerson = []
    }
    typeof fn === 'function' && fn(type, item)
  }
  return { state, onSearchInput, searchClick }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>

<template>
  <!-- @node-click="nodeClick" -->
  <div class="department-tree-wrap" v-loading="state.treeLoadState">
    <el-tree
      ref="treeRef"
      :load="loadNode"
      :highlight-current="true"
      node-key="id"
      :default-expanded-keys="state.treeDefaultExpand"
      :current-node-key="state.current?.id"
      :default-expand-all="false"
      :expand-on-click-node="false"
      :props="{ children: 'childList', label: 'name' }"
      lazy
      @current-change="onCurrentChange"
      @node-expand="onNodeExpand"
    >
      <template #default="{ node, data }">
        <div v-if="data.type && data.type === 'more'" class="more-btn">
          <div class="btn-bg">
            <el-button :loading="data.isClick" type="primary">加载更多...</el-button>
          </div>
        </div>
        <div v-else-if="!data.isHide" class="custom-tree-node ellipsis" :title="node.label">
          <span class="iconfont" :class="[node.isCurrent ? 'icon-folder-open' : 'icon-folder']"></span>
          <span>{{ node.label }}</span>
        </div>
        <!-- <menu-action v-if="node.isCurrent && data.id !== '1' && data.type !== 'more' && hasEdit">
          <menu-action-item @click.stop="onActionClick('add-department', data, node)">新增子部门</menu-action-item>
          <menu-action-item @click.stop="onActionClick('edit', data)">编辑</menu-action-item>
          <menu-action-item @click.stop="onActionClick('delete', data)">删除</menu-action-item>
          <menu-action-item :disabled="!canMoveUp(node)" @click.stop="onActionClick('up', data, node)">
            上移
          </menu-action-item>
          <menu-action-item :disabled="!canMoveDown(node)" @click.stop="onActionClick('down', data, node)">
            下移
          </menu-action-item>
        </menu-action> -->
      </template>
    </el-tree>
  </div>
  <!-- 编辑部门 -->
  <department-edit
    v-if="state.showEdit"
    @success="departmentEditSuccess"
    :departementAddState="state.departmentAddState"
    :departement="state.editDepartment"
    v-model:visible="state.showEdit"
  />
</template>
<script>
import { nextTick, reactive, ref } from 'vue'
// import MenuAction, { MenuActionItem } from '@/views/components/menu-action'
import { useDeleteConfirm } from '@/hooks/ui'
import DepartmentEdit from './departement-edit.vue'
import { ElMessage } from 'element-plus'
import { delDepartment, updateDepartmentSort } from '@/apis/department'
import { useStore } from 'vuex'
import { getLazyTreeDepartment } from '@/apis'
import * as TYPE from '@/store/types'
export default {
  name: 'departmentTree',
  components: { DepartmentEdit },
  emits: ['select'],
  props: {
    hasEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const deleteConfirm = useDeleteConfirm()
    const store = useStore()
    const treeRef = ref(null)
    const state = reactive({
      current: {}, //当前选中节点
      treeDefaultExpand: [],
      showEdit: false,
      departmentAddState: false,
      treeLoadState: false, //树形加载
      treeData: [],
      totalTreeList: [],
      treeNodePage: {
        pageNo: 1,
        pageSize: 20
      }
    })
    state.current = store.state.topDepartment
    console.log('state.current : ', state.current)
    // eslint-disable-next-line no-unused-vars
    const getTreeNode = parent_id => {
      return getLazyTreeDepartment(Object.assign(state.treeNodePage, { parent_id: parent_id }))
    }
    //list:总数据,length:总长，page:一次渲染的长度
    const subsection = (list, length, page) => {
      let lng = 0
      let start = 0
      let timer = setInterval(() => {
        lng += 1
        let data = list.slice(start, start + page)
        data.forEach(item => {
          state.totalTreeList.push(item)
        })
        start += page
        if (lng > length / page) {
          clearInterval(timer)
        }
      }, 10)
    }
    const refreshNodeBy = id => {
      let node = treeRef.value.getNode(id) // 通过节点id找到对应树节点对象
      node.loaded = false
      node.expand() // 主动调用展开节点方法，重新查询该节点下的所有子节点
    }

    // 交换
    const swap = async (type, data, node) => {
      const nodeList = node.parent.childNodes
      const index = nodeList.findIndex(item => item === node)
      let targetNode = null
      if (type === 'up') {
        const preNode = nodeList[index - 1]
        if (!preNode) return false
        treeRef.value.remove(node)
        treeRef.value.insertBefore(node.data, preNode)
        targetNode = preNode
      } else {
        const nextNode = nodeList[index + 1]
        if (!nextNode) return false
        treeRef.value.remove(node)
        treeRef.value.insertAfter(node.data, nextNode)
        targetNode = nextNode
      }
      const params = [
        { id: node.data.id, sort: targetNode.data.sort },
        { id: targetNode.data.id, sort: node.data.sort }
      ]
      await updateDepartmentSort(params)
    }
    const handleDelete = async department => {
      const res = await delDepartment(department.id)
      if (res.isOk) {
        await refreshNodeBy(store.state.topDepartment.id)
        ElMessage.success('删除部门成功')
      }
    }
    const departmentEditSuccess = async () => {
      //部门修改成功
      state.treeNodePage.pageNo = 1
      await refreshNodeBy(store.state.topDepartment.id)
      state.treeDefaultExpand = [state.current.id]
    }

    // 操作点击事件
    const onActionClick = async (type, data, node) => {
      // state.currentDepartment = data
      switch (type) {
        case 'edit':
          state.departmentAddState = false
          delete data.childList
          state.editDepartment = data
          state.showEdit = true
          break
        case 'delete':
          if (await deleteConfirm('您确认要删除该部门吗?')) {
            handleDelete(data)
          }
          break
        case 'up':
          swap(type, data, node)
          break
        case 'down':
          swap(type, data, node)
          break
        case 'add-department': //新增子部门
          state.departmentAddState = true
          state.editDepartment = {
            parent_id: data.id,
            parent_ids: [data.id],
            employeeIds: [],
            employeeNames: [],
            name: '',
            sort: data.sort
          }
          state.showEdit = true
          break
      }
    }

    // const nextPage = () => {
    //   //分页
    // }
    //加载第一节点
    const loadFirstNode = async resolve => {
      state.treeLoadState = true
      const res = await getTreeNode('')
      state.treeLoadState = false
      if (res.data.list.length > 0) {
        let obj = {
          type: 'department',
          ...res.data.list[0]
        }
        store.commit(TYPE.TOP_DEPARTMENT, obj)
        if (!obj.id) return false
        resolve(res.data.list)
        nextTick(() => {
          treeRef.value && treeRef.value.setCurrentKey(obj.id)
        })
        emit('select', res.data.list[0].id, res.data.list[0])
      }
    }
    //加载节点的子节点集合
    const loadChildNode = async (node, resolve) => {
      //
      state.treeLoadState = true
      state.treeDefaultExpand = [node.data.id]
      state.totalTreeList = []
      const res = await getTreeNode(node.data.id)
      state.treeLoadState = false
      let resultList = res.data.list
      console.log('resultList: ', resultList)

      if (state.treeNodePage.pageNo * state.treeNodePage.pageSize <= res.data.count) {
        //可再翻页则增加一个按钮加载更多
        resultList.push({ name: '加载更多...', type: 'more', pid: resultList[resultList.length - 1].pid, pageNo: 1 })
      }

      subsection(resultList, resultList.length, 100)
      let totalTreeList = await state.totalTreeList

      node.data.children = totalTreeList
      node.data.childList = totalTreeList
      return resolve(totalTreeList)
    }
    const loadNode = async (node, resolve) => {
      console.log('node:--------loadNode ', node)
      //懒加载树节点
      if (node.level == 0) {
        console.log('node.data: ', node.data)
        loadFirstNode(resolve)
      }
      if (node.level > 0) {
        loadChildNode(node, resolve)
      }
    }
    const onNodeExpand = (data, node) => {
      console.log('onNodeExpand=---data: ', data)
      console.log('node: ', node)
      state.treeNodePage.pageNo = 1
    }
    const onCurrentChange = async (data, node) => {
      console.log('onCurrentChange------data: ', data)
      console.log('state.current:------------onCurrentChange ', state.current)
      if (data.type && data.type === 'more') {
        data.pageNo += 1
        state.treeNodePage.pageNo = data.pageNo
        data['isClick'] = true
        let moreList = await getTreeNode(data.pid)
        let children = await node.parent.data.children
        data['isClick'] = false
        let index = children.findIndex(item => item.type && item.type === 'more')
        node.parent.data.children.splice(index, 0, ...moreList.data.list)
        if (state.treeNodePage.pageNo * state.treeNodePage.pageSize >= moreList.data.count) {
          //没有分页
          //删除最后一个加载更多按钮
          node.parent.data.children.splice(node.parent.data.children.length - 1, 1)
          node.parent.childNodes.splice(node.parent.childNodes.length - 1, 1)
        }
        console.log('state.current: ', state.current)
        //选中当前已选的状态
        treeRef.value.setCurrentKey(state.current.id)
        return
      }
      if (state.current?.id === data.id) return false
      state.current = data
      emit('select', data.id, data)
    }

    // 判断是否可以向上移动
    const canMoveUp = node => {
      const parent = node.parent
      if (parent.childNodes[0] === node) {
        return false
      }
      return true
    }

    // 判断是否可以向下移动
    const canMoveDown = node => {
      const parent = node.parent
      const childLen = parent.childNodes.length
      if (parent.childNodes[childLen - 1] === node) {
        return false
      }
      return true
    }
    return {
      treeRef,
      departmentEditSuccess,
      state,
      canMoveDown,
      canMoveUp,
      onActionClick,
      onCurrentChange,
      loadNode,
      onNodeExpand
      // nextPage
    }
  }
}
</script>
<style lang="scss" scoped>
@import './department-tree.scss';
</style>

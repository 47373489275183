<template>
  <div class="manager-list-wrap" v-loading="!state.loaded">
    <div class="manager-name">{{ name }}({{ employeeState.count }}人)</div>
    <div class="alert-wrap">
      <div class="text">“主管”为默认角色， 请到部门设置中设置主管 设置完成后自动同步</div>
    </div>
    <template v-if="state.loaded">
      <template v-if="employeeState.list?.length || employeeState.count">
        <div class="table-wrapper">
          <el-table
            :max-height="maxTableHeight"
            cell-class-name="custom-table-cell"
            header-row-class-name="custom-table-header"
            :data="employeeState.list"
            ref="tableRef"
            empty-text="暂无数据"
            style="width: 100%"
          >
            <el-table-column align="center" show-overflow-tooltip prop="name" label="姓名" width="120">
              <template #default="scope">
                <wxview type="userName" :openid="scope.row.name">{{ scope.row.name }}</wxview>
              </template>
            </el-table-column>
            <el-table-column align="center" fit show-overflow-tooltip prop="departmentName" label="部门">
              <template #default="scope">
                <span type="departmentName" :openid="scope.row.departmentName.join(',')">
                  {{ scope.row.departmentName.join(',') }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="data_scope" label="管理范围">
              <!-- <template #default="{ row }">{{ CONTROL_SCOPE_DICT[row.data_scope] }}</template> -->
              <template #default="scope">
                <span type="departmentName" :openid="scope.row.dutyDepartmentName.join(',')">
                  {{ scope.row.dutyDepartmentName.join(',') }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="100">
              <template #default="{ row }">
                <div class="action-wrap">
                  <el-button size="mini" type="text" @click="onSettingClick(row)">设置</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <pagination-block
          v-if="employeeState.list?.length"
          @page-change="handleCurrentChange"
          v-model="employeeState.query.pageNo"
          :page-size="employeeState.query.pageSize"
          @size-change="handleSizeChange"
          :total="employeeState.count"
        ></pagination-block>
      </template>
      <template v-else>
        <empty-employee></empty-employee>
      </template>
    </template>
    <department-select
      ref="departmentSelectRef"
      v-show="false"
      v-model="state.department"
      @confirm="onDepartmentSelectConfirm"
    ></department-select>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from 'vue'
import { useEmployee } from '@/hooks/sys'
import { batchChangeEmployeeDepartment } from '@/apis'
import EmptyEmployee from './empty.vue'
import DepartmentSelect from '@/components/department-select'
import { CONTROL_SCOPE_DICT } from '@/common/const'
import PaginationBlock from '@/components/pagination'
export default {
  name: 'managerList',
  components: { EmptyEmployee, DepartmentSelect, PaginationBlock },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    maxTableHeight: {
      type: Number,
      default: 100
    }
  },
  setup() {
    const departmentSelectRef = ref(null)
    const state = reactive({
      loaded: false,
      alertDesc: '"主管” 为默认角色，请到部门设置中设置主管，设置完成后自动同步',
      identity: '1',
      jumperPage: '',
      currentEmployee: {},
      departments: []
    })
    const { employeeState, initEmployeeListByIdentity } = useEmployee()
    const handleCurrentChange = page => {
      employeeState.query.pageNo = page
      initEmployeeListByIdentity(state.identity)
    }
    const handleSizeChange = size => {
      employeeState.query.pageSize = size
      employeeState.query.pageNo = 1
      initEmployeeListByIdentity(state.identity)
    }
    const jumperClick = () => {
      if (!state.jumperPage) return false
      employeeState.query.pageNo = state.jumperPage - 0 || 1
      initEmployeeListByIdentity(state.identity)
    }
    // 设置按钮点击事件
    const onSettingClick = row => {
      state.currentEmployee = row
      if (departmentSelectRef.value) {
        departmentSelectRef.value.handleTrigger()
      }
    }
    // 人员部门设置
    const onDepartmentSelectConfirm = (ids, list) => {
      console.log('onDepartmentSelectConfirm', ids, list)
      const names = list.map(item => item.name)
      const id = state.currentEmployee.id
      if (!id) return false
      const userList = list.map(item => item.userid)
      const params = {
        type: 'duty',
        checkedList: [state.currentEmployee.id],
        department: ids,
        departmentName: names,
        userList: userList
      }
      doUpdateEmployeeDepartment(params, () => {
        // 清空选中
        state.departments = []
        // 刷新人员列表
        initEmployeeListByIdentity(state.identity)
      })
    }
    /** 更新人员所在部门
     * @param {Object} params 参数
     * @param {Function} successFn 成功回调
     */
    const doUpdateEmployeeDepartment = async (params, successFn) => {
      if (params) {
        try {
          await batchChangeEmployeeDepartment(params)
          if (successFn && typeof successFn === 'function') {
            successFn()
          }
        } catch (error) {
          console.log('batchChangeEmployeeDepartment error', error)
        }
      }
    }
    onMounted(async () => {
      await initEmployeeListByIdentity(state.identity)
      state.loaded = true
    })
    return {
      CONTROL_SCOPE_DICT,
      departmentSelectRef,
      state,
      employeeState,
      handleCurrentChange,
      handleSizeChange,
      jumperClick,
      onSettingClick,
      onDepartmentSelectConfirm
    }
  }
}
</script>
<style lang="scss" scoped>
@import './manager-list.scss';
</style>

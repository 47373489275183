import http from '@/common/http'

/**
 * 删除部门
 * @returns
 */
export const delDepartment = id => {
  return http.post('/basic/department/delete', { id })
}
/**
 * 部门位置修改
 * @param {Array} params
 * @returns
 */
export const updateDepartmentSort = params => {
  return http.post('/basic/department/updateSort', { data: params })
}

/**
 * 新增部门
 * @param {*} params
 * @returns
 */
export const saveDepartment = params => {
  return http.post('/basic/department/save', params)
}

/**
 * 编辑部门
 * @param {*} params
 * @returns
 */
export const updateDepartment = params => {
  return http.post('/basic/department/update', params)
}

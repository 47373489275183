<template>
  <div class="role-wrapper">
    <component :id="id" :name="name" :is="currentComponent" :maxTableHeight="state.maxTableHeight"></component>
  </div>
</template>
<script>
import { reactive, onMounted } from 'vue'
import AdminList from './admin-list.vue'
import SubAdminList from './sub-admin-list.vue'
import ManagerList from './manager-list.vue'
import PositionList from './position-list.vue'
export default {
  name: 'roleIndex',
  components: { AdminList, SubAdminList, ManagerList, PositionList },
  props: {
    // 父组件传值限定为AdminList, SubAdminList, ManagerList, PositionList
    currentComponent: {
      type: String,
      required: true,
      default: 'AdminList'
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    const state = reactive({
      maxTableHeight: 100
    })
    onMounted(() => {
      state.maxTableHeight = window.innerHeight - 320
    })
    return {
      state
    }
  }
}
</script>
<style lang="scss">
@import './index.scss';
</style>

<template>
  <div class="position-list-wrap" v-loading="!state.loaded">
    <div class="position-name">
      <span class="name-text">{{ name }}({{ employeeState.count }}人)</span>
      <el-button type="default" round size="mini" @click="openEditDepartment">编辑</el-button>
    </div>
    <div class="filter-wrap">
      <el-button type="primary" round size="mini" @click="onAddEmployeeClick">添加员工</el-button>
      <el-button type="default" round size="mini" @click="onBatchDelClick" :disabled="state.checkedList.length === 0">
        批量删除
      </el-button>
    </div>
    <template v-if="state.loaded">
      <template v-if="employeeState.list?.length || employeeState.count">
        <div class="table-wrapper">
          <el-table
            :max-height="maxTableHeight"
            cell-class-name="custom-table-cell"
            header-row-class-name="custom-table-header"
            :data="employeeState.list"
            ref="tableRef"
            @selection-change="hanleSelectionChange"
            empty-text="暂无数据"
            style="width: 100%"
          >
            <el-table-column align="center" type="selection" width="55"></el-table-column>
            <el-table-column align="left" show-overflow-tooltip prop="name" label="姓名" width="120">
              <template #default="scope">
                <wxview type="userName" :openid="scope.row.name">{{ scope.row.name }}</wxview>
              </template>
            </el-table-column>
            <el-table-column align="left" prop="departmentName" label="部门">
              <template #default="scope">
                <el-popover placement="right-end" popper-class="dark-popover" :width="220" trigger="hover">
                  <div>
                    <template v-for="(item, index) in scope.row.departmentName" :key="item">
                      <span type="departmentName" :openid="item">{{ item }}</span>
                      <template v-if="index !== scope.row.departmentName.length - 1">，</template>
                    </template>
                  </div>
                  <template #reference>
                    <div class="ellipsis column-popover">
                      <template v-for="(item, index) in scope.row.departmentName" :key="item">
                        <span type="departmentName" :openid="item">{{ item }}</span>
                        <template v-if="index !== scope.row.departmentName.length - 1">，</template>
                      </template>
                    </div>
                  </template>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="data_scope" label="管理范围">
              <template #default="scope">
                <template v-for="(item, index) in scope.row.positionDepartment" :key="item">
                  <span type="departmentName" :openid="item">{{ item }}</span>
                  <template v-if="index !== scope.row.positionDepartment.length - 1">,</template>
                </template>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="100">
              <!-- <template #default="{ row }">
                <div class="action-wrap">
                  <el-button size="mini" type="text" @click="onSettingClick(row)">设置</el-button>
                </div>
              </template> -->
            </el-table-column>
          </el-table>
        </div>
        <pagination-block
          v-if="employeeState.list?.length"
          @page-change="handleCurrentChange"
          v-model="employeeState.query.pageNo"
          :page-size="employeeState.query.pageSize"
          @size-change="handleSizeChange"
          :total="employeeState.count"
        ></pagination-block>
      </template>
      <template v-else>
        <empty-employee></empty-employee>
      </template>
    </template>
    <user-select
      ref="userSelectRef"
      v-show="false"
      v-model="state.employees"
      @confirm="onUserSelectConfirm"
      placeholder="添加员工"
    ></user-select>
    <department-select
      ref="departmentSelectRef"
      v-show="false"
      v-model="state.department"
      @confirm="onDepartmentSelectConfirm"
    ></department-select>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ref, reactive, onMounted, onUnmounted, watch, nextTick } from 'vue'
import { useEmployee } from '@/hooks/sys'
import { useMessageBoxConfirm } from '@/hooks/ui'
import { updateEmployeePosition, batchChangeEmployeeDepartment } from '@/apis'
import { ElMessage } from 'element-plus'
import * as TYPE from '@/store/types'
import { useStore } from 'vuex'
import EmptyEmployee from './empty.vue'
import UserSelect from '@/components/user-select'
import DepartmentSelect from '@/components/department-select'
import { CONTROL_SCOPE_DICT } from '@/common/const'
import PaginationBlock from '@/components/pagination'

export default {
  name: 'positionList',
  components: { EmptyEmployee, UserSelect, DepartmentSelect, PaginationBlock },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    maxTableHeight: {
      type: Number,
      default: 100
    }
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    console.log('props: ', props)
    const store = useStore()
    const userSelectRef = ref(null)
    const departmentSelectRef = ref(null)
    const state = reactive({
      loaded: false,
      checkedList: [],
      currentEmployee: {},
      jumperPage: '',
      employees: [],
      departments: []
    })
    // 重置state
    const resetState = () => {
      state.loaded = false
      state.checkedList = []
      state.currentEmployee = {}
      state.jumperPage = ''
      state.employees = []
      state.departments = []
    }
    const { MessageBoxConfirm } = useMessageBoxConfirm()
    const { employeeState, initEmployeeListByPositionId } = useEmployee()
    const handleCurrentChange = page => {
      employeeState.query.pageNo = page
      initEmployeeListByPositionId(props.id)
    }
    const handleSizeChange = size => {
      employeeState.query.pageSize = size
      employeeState.query.pageNo = 1
      initEmployeeListByPositionId(props.id)
    }
    const jumperClick = () => {
      if (!state.jumperPage) return false
      employeeState.query.pageNo = state.jumperPage - 0 || 1
      initEmployeeListByPositionId(props.id)
    }
    // table选择事件
    const hanleSelectionChange = selection => {
      state.checkedList = selection
    }
    // 表单选中删除事件
    const onBatchDelClick = async () => {
      if (!state.checkedList.length) {
        ElMessage.warning('请选择数据')
        return false
      }
      if (await MessageBoxConfirm('您确认要删除所选数据吗?')) {
        console.log('onBatchDelClick', state.checkedList)
        const params = {
          isSave: false,
          employees: state.checkedList.map(item => {
            return item.id
          }),
          position: props.id,
          positionName: ''
        }
        doUpdateEmployeePosition(params, () => {
          // 刷新人员列表
          initEmployeeListByPositionId(props.id)
          // 清除选中
          state.checkedList = []
        })
      }
    }
    // 设置按钮点击事件
    const onSettingClick = row => {
      state.currentEmployee = row
      if (departmentSelectRef.value) {
        departmentSelectRef.value.triggerDialog()
      }
    }
    // 添加员工按钮点击事件
    const onAddEmployeeClick = () => {
      if (userSelectRef.value) {
        userSelectRef.value.triggerDialog()
      }
    }
    // 角色-岗位-添加员工
    const onUserSelectConfirm = (ids, list) => {
      console.log('onUserSelectConfirm', ids, list)
      const params = {
        isSave: true,
        employees: ids,
        position: props.id,
        positionName: props.name
      }
      doUpdateEmployeePosition(params, () => {
        // 清空选中
        state.employees = []
        // 刷新人员列表
        initEmployeeListByPositionId(props.id)
      })
    }
    // 人员部门设置
    const onDepartmentSelectConfirm = (ids, list) => {
      console.log('onDepartmentSelectConfirm', ids, list)
      const names = list.map(item => item.name)
      const id = state.currentEmployee.id
      if (!id) return false
      const userList = list.map(item => item.userid)
      const params = {
        positionId: props.id,
        type: 'position',
        checkedList: [state.currentEmployee.id],
        department: ids,
        departmentName: names,
        userList: userList
      }
      doUpdateEmployeeDepartment(params, () => {
        // 清空选中
        state.departments = []
        // 刷新人员列表
        initEmployeeListByPositionId(props.id)
      })
    }
    /** 更新岗位人员
     * @param {Object} params 参数
     * @param {Function} successFn 成功回调
     */
    const doUpdateEmployeePosition = async (params, successFn) => {
      if (params) {
        try {
          await updateEmployeePosition(params)
          if (successFn && typeof successFn === 'function') {
            successFn()
          }
        } catch (error) {
          console.log('updateEmployeePosition error', error)
        }
      }
    }
    /** 更新人员所在部门
     * @param {Object} params 参数
     * @param {Function} successFn 成功回调
     */
    const doUpdateEmployeeDepartment = async (params, successFn) => {
      if (params) {
        try {
          await batchChangeEmployeeDepartment(params)
          if (successFn && typeof successFn === 'function') {
            successFn()
          }
        } catch (error) {
          console.log('batchChangeEmployeeDepartment error', error)
        }
      }
    }
    const openEditDepartment = () => {
      //修改角色 显示弹出框
      store.commit(TYPE.CURRENT_ROLE_STATE, !store.state.currentRoleState)
    }
    const stop = watch(
      () => props.id,
      async id => {
        if (id) {
          resetState()
          await nextTick()
          await initEmployeeListByPositionId(id)
          state.checkedList = []
        }
        state.loaded = true
      },
      { immediate: true }
    )
    watch(
      () => store.state.currentRoleState,
      val => {
        if (!val) {
          console.log('currentRoleState----------------: ', val)
          //编辑弹框关闭后跟新调用
          initEmployeeListByPositionId(props.id)
        }
      },
      { immediate: true }
    )
    onUnmounted(() => {
      typeof stop === 'function' && stop()
    })
    onMounted(() => {
      console.log('userSelectRef.value', userSelectRef.value)
    })
    return {
      CONTROL_SCOPE_DICT,
      state,
      userSelectRef,
      departmentSelectRef,
      employeeState,
      handleCurrentChange,
      handleSizeChange,
      jumperClick,
      hanleSelectionChange,
      onAddEmployeeClick,
      onBatchDelClick,
      onUserSelectConfirm,
      onSettingClick,
      onDepartmentSelectConfirm,
      openEditDepartment
    }
  }
}
</script>
<style lang="scss" scoped>
@import './position-list.scss';
</style>
